export const environment = {
  production: false,
  baseHref: '/',
  redirectUri: 'https://reporting-ui.qas.ag2goservices.amgreetings.com/',
  apiScopeUrl: 'api://79145347-2049-4b7f-a85e-143db4e3d797/access_as_user',
  clientId: 'eab20856-bd5b-470b-9a97-52147ac0eb53',
  postLogoutRedirectUri: 'https://reporting-ui.qas.ag2goservices.amgreetings.com/landing',
  webApi: 'https://reporting-api.qas.ag2goservices.amgreetings.com/api',
  authApi: 'https://mobile-auth.qas.ag2goservices.amgreetings.com/api/auth/sign-in',
  userRolesAuthorization: true,
  tablePaginationSizes: [50, 200, 300, 500],
  pageableAllItems: 99999,
  noLoadingSpinnerHeader: 'loading-spinner-disabled',
  httpRequestChunkSize: 100
};
